import axios from "axios";
const context = "/deviceservice";

/***
 * 获取监控指标
 */
export function getTodayRealTimeIndex(data:any){
    return axios.get(`${context}/visualData/todayRtIndex`)
  }

  export function getRtIndex(data:any){
    return axios.get(`${context}/visualData/rtIndex`)
  }


  /***
   * 血压检测数据
   */
  export function getTodayAllPressureData(data:any){
    return axios.get(`${context}/visualData/todayAllPressureData`)
  }

  /***
   * 温度检测数据
   */
  export function getTotalAllTemperateData(data:any){
    return axios.get(`${context}/visualData/totalAllTemperateData`)
  }


/***
 * 获取用户统计数据
 */
export function getUserStatistics( data:any ){
  return axios.get(`/ucenter/sysUser/userStatistics`,data)
}

/***
 * 获取用户基本信息
 */
export function getUserDetailById( data:any ){
  return axios.get(`/ucenter/sysUser//userDetail/`+data.userId)
}

/***
 * 获取一周内的血压数据
 */
export function getWeekOfPresureStatisticData( data:any ){
  return axios.get(`${context}/visualData/weekOfPresureStatisticData`, data)
}

export function getWeekOfTemperatureStatisticData( data:any ){
  return axios.get(`${context}/visualData/weekOfTemperatureStatisticData`, data)
}